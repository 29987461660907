import { AuthenticateActions } from "../actions/authenticate";
import { CandidatesActions } from "../actions/candidates";
import { RequestActions } from "../actions/requests";
import {
  DEFAULT_REQUESTS_STATE,
  DORMANT_REQUEST,
  FAILED_REQUEST,
  FULFILLED_REQUEST,
  PENDING_REQUEST,
  Requests,
  RequestsState,
  Request
} from "../state/requestsState";
import { Action } from "../utils";

export function requestsReducer(
  state: RequestsState = DEFAULT_REQUESTS_STATE,
  action: Action<any>
): RequestsState {
  return {
    ...state,
    ...{
      statuses: requestsStatusesReducer(state.statuses, action),
      errors: requestsErrorsReducer(state.errors, action)
    }
  };
}

function requestsStatusesReducer(
  statuses: Record<string, Request>,
  action: Action<any>
): Record<string, Request> {
  switch (action.type) {
    case AuthenticateActions.AUTHENTICATE_REQUEST:
      return { ...statuses, [Requests.authenticateUser]: PENDING_REQUEST };
    case AuthenticateActions.AUTHENTICATE_SUCCESS:
      return { ...statuses, [Requests.authenticateUser]: FULFILLED_REQUEST };
    case AuthenticateActions.AUTHENTICATE_ERROR:
      return { ...statuses, [Requests.authenticateUser]: FAILED_REQUEST };

    case CandidatesActions.FETCH_CANDIDATES_REQUEST:
      return { ...statuses, [Requests.fetchCandidates]: PENDING_REQUEST };
    case CandidatesActions.FETCH_CANDIDATES_SUCCESS:
      return { ...statuses, [Requests.fetchCandidates]: FULFILLED_REQUEST };
    case CandidatesActions.FETCH_CANDIDATES_ERROR:
      return { ...statuses, [Requests.fetchCandidates]: FAILED_REQUEST };

    case CandidatesActions.CREATE_CANDIDATE_REQUEST:
      return { ...statuses, [Requests.createCandidate]: PENDING_REQUEST };
    case CandidatesActions.CREATE_CANDIDATE_SUCCESS:
      return { ...statuses, [Requests.createCandidate]: FULFILLED_REQUEST };
    case CandidatesActions.CREATE_CANDIDATE_ERROR:
      return { ...statuses, [Requests.createCandidate]: FAILED_REQUEST };

    case CandidatesActions.DELETE_CANDIDATE_REQUEST:
      return { ...statuses, [Requests.deleteCandidate]: PENDING_REQUEST };
    case CandidatesActions.DELETE_CANDIDATE_SUCCESS:
      return { ...statuses, [Requests.deleteCandidate]: FULFILLED_REQUEST };
    case CandidatesActions.DELETE_CANDIDATE_ERROR:
      return { ...statuses, [Requests.deleteCandidate]: FAILED_REQUEST };


    case RequestActions.RESET_FETCH_CANDIDATES_REQUEST:
      return { ...statuses, [Requests.fetchCandidates]: DORMANT_REQUEST}
    case RequestActions.RESET_CREATE_CANDIDATE_REQUEST:
      return { ...statuses, [Requests.createCandidate]: DORMANT_REQUEST}
    case RequestActions.RESET_DELETE_CANDIDATE_REQUEST:
      return { ...statuses, [Requests.deleteCandidate]: DORMANT_REQUEST}

    default:
      return statuses;
  }
}

function requestsErrorsReducer(
  errors: Record<string, string | null>,
  action: Action<any>
): Record<string, string | null> {

  switch (action.type) {
      
    case CandidatesActions.FETCH_CANDIDATES_ERROR:
      return { ...errors, [Requests.fetchCandidates]: action.error };
  
    case CandidatesActions.CREATE_CANDIDATE_ERROR:
      return { ...errors, [Requests.createCandidate]: action.error };

    case CandidatesActions.DELETE_CANDIDATE_ERROR:
      return { ...errors, [Requests.deleteCandidate]: action.error };
  
    default:
      return errors;
  }
}