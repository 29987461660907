import axios from "axios";
import { Credentials } from "../redux/state/authenticateState";
import { getToken, refreshToken } from "../utils/authToken";

const url = process.env.REACT_APP_API_URL

const axiosApiInstance = axios.create()

axiosApiInstance.interceptors.request.use(
  async config => {
    const token = getToken()
    config.headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
    return config
  },
  error => {
    Promise.reject(error)
  }
)
axiosApiInstance.interceptors.response.use((response) => {
  return response
}, async function (error) {
  const originalRequest = error.config
  if(error.response && error.response.status === 401 && !originalRequest._retry){
    originalRequest._retry = true
    const accessToken = await refreshToken()
    axiosApiInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken?.AuthenticationResult.IdToken}`
    return axiosApiInstance(originalRequest)
  }
  return Promise.reject(error)
}
)

export function auth<CognitoResponse>(credentials: Credentials): Promise<CognitoResponse> {
  const data = { 
    "AuthParameters": { "USERNAME": `${credentials.email}`, "PASSWORD": `${credentials.password}`}, 
    "AuthFlow": "USER_PASSWORD_AUTH", 
    "ClientId": process.env.REACT_APP_CLIENT_ID
  }

  const url = process.env.REACT_APP_COGNITO_URL

  return axios
    .post(
      url,
      data,
      {
        headers: {
          "Content-Type": "application/x-amz-json-1.1",
          "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth"
        }
      }
    )
    .then(response => response.data)
    .catch(error => {
      
      const body = error.response?.data;
      throw error.message + (body ? ": " + body : "");
    });
}

export function refresh<CognitoResponse>(token: string) : Promise<CognitoResponse>{
    const data = { "AuthParameters": { "REFRESH_TOKEN": token }, 
    "AuthFlow": "REFRESH_TOKEN_AUTH", "ClientId": process.env.REACT_APP_CLIENT_ID}
    const url = process.env.REACT_APP_COGNITO_URL
  
    return axios
      .post(
        url,
        data,
        {
          headers: {
            "Content-Type": "application/x-amz-json-1.1",
            "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth"
          }
        }
      )
      .then(response => response.data)
      .catch(error => {
        
        const body = error.response?.data;
        throw error.message + (body ? ": " + body : "");
      });
}
export function health<T>(path: string) : Promise<T> {
  return axiosApiInstance
  .get(path)
  .then((response) => response.data)
      .catch((error) => {
      const body = error.response?.data;

      throw error.message + (body ? ": " + body : "");
    });
}

export function get<T>(path: string): Promise<T> {
  return axiosApiInstance
    .get(url + path)
    .then((response) => response.data)
    .catch((error) => {
      const body = error.response?.data;

      throw error.message + (body ? ": " + body : "");
    });
}

export function post<T>(path: string, data: unknown): Promise<T> {
  return axiosApiInstance
    .post(url + path, data)
    .then((response) => response.data)
    .catch((error) => {
      const body = error.response?.data;

      throw error.message + (body ? ": " + body : "");
    });
}

export function del<T>(path: string): Promise<T> {
  return axiosApiInstance
    .delete(url + path)
    .then((response) => response.data)
    .catch((error) => {
      const body = error.response?.data;

      throw error.message + (body ? ": " + body : "");
    });
}
