import { connect } from 'react-redux';
import PageHeader from './PageHeader';
import "./Layout.scss";

interface LayoutProps {
  children: JSX.Element
}

function LayoutComponent(props: LayoutProps) {
  return (
    <div className="app-page">
      <PageHeader />
      <div className="app-page-content">
        {props.children}
      </div>
    </div >
  );

}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutComponent);