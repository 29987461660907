import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import { getToken } from "../../../utils/authToken";
import "./PageHeader.scss";

export const PageHeaderComponent = (): JSX.Element => {
  const token = getToken();

  return (
    <h1 className="page-header">
      IIG Interview Registration Portal

      {token && (<NavLink className="logout-link" title="Logout" to="/logout">
        <Icon name="sign-out" className="sign-out-icon" />
      </NavLink>)}
    </h1>
  );
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps() {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PageHeaderComponent);
