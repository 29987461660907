import {
    all,
    AllEffect,
    call,
    CallEffect,
    ForkEffect,
    put,
    PutEffect,
    takeLatest,
  } from "redux-saga/effects";
  import { TokenService } from "../../services/tokenService";
  import { Action } from "../utils";
  import {
    authenticateSuccess,
    authenticateError,
    AuthenticateActions,
  } from "../actions/authenticate";
import { Credentials, CognitoResponse } from "../state/authenticateState";
  
  const service = new TokenService();
  
  export function* authenticateWatcher(): Generator<
    AllEffect<ForkEffect<never>>,
    void,
    unknown
  > {
    yield all([
      takeLatest(AuthenticateActions.AUTHENTICATE_REQUEST, authenticateRequestWatcher)
    ]);
  }
  
  function* authenticateRequestWatcher(
    action: Action<Credentials>
  ): Generator<CallEffect | PutEffect<Action<void>>, void, CognitoResponse> {
    try {
      if (typeof action.payload === "undefined") {
        throw new Error("No credentials provided");
      }
  
      const credentials = action.payload;
  
      const response = yield call(service.authenticateUser, credentials);
      response.ExpiryDate = new Date(new Date().getTime() + response.AuthenticationResult?.ExpiresIn * 1000)
      sessionStorage.setItem("token", JSON.stringify(response));

      yield put(authenticateSuccess());

      location.reload();
  
    } catch (error) {
      if (error instanceof Error) {
        yield put(authenticateError(error.message));
      } else if (typeof error === "string") {
        yield put(authenticateError(error));
      } else {
        yield put(authenticateError("Unknown error."));
      }
    }
  }