import { connect } from "react-redux";
import { Dispatch } from "../../../redux/store";
import { deleteCandidateRequest } from "../../../redux/actions/candidates";
import { Button } from "semantic-ui-react";
import { Candidate } from "../../../redux/state/candidatesState";
import { FAILED_REQUEST, FULFILLED_REQUEST, Request } from "../../../redux/state/requestsState";
import { resetDeleteCandidateRequest } from "../../../redux/actions/requests";
import { getDeleteCandidateError, getDeleteCandidateRequest } from "../../../redux/selectors/requestsSelector";
import { State } from "../../../redux/state/rootState";
import { useEffect } from "react";
import AsyncElement from "../../Generic/Async/AsyncElement";
import ErrorCard from "../../Generic/Card/ErrorCard";
import Modal from "../../Generic/Modal/Modal";

interface DeleteModalProps {
  candidate: Candidate;
  closeModal: () => void;
  deleteCandidateRequest: Request;
  deleteCandidateError: string | null;
  deleteCandidate: (candidate: Candidate) => void;
  resetDeleteCandidateRequest: () => void;
}

export const DeleteModalComponent = (props: DeleteModalProps): JSX.Element => {
  const candidate = props.candidate;
  const closeModal = props.closeModal;
  const deleteCandidateRequest = props.deleteCandidateRequest;
  const deleteCandidateError = props.deleteCandidateError;
  const deleteCandidate = props.deleteCandidate;
  const resetDeleteCandidateRequest = props.resetDeleteCandidateRequest;

  function reset(){
      resetDeleteCandidateRequest();
      closeModal();
  }
  useEffect(() => {
    if (deleteCandidateRequest === FULFILLED_REQUEST) {
      resetDeleteCandidateRequest();
      closeModal();
    }
  }, [deleteCandidateRequest]);

  return <Modal title="Delete Candidate" size="tiny" closeModal={reset}>
    <span>
      {
        deleteCandidateRequest === FAILED_REQUEST &&
        <ErrorCard>
          <span>{deleteCandidateError || "An unknown error occured"}</span>
        </ErrorCard>
      }

      <div>
        Are you sure you&apos;d like to delete this candidate?
      </div>
      
      <div className="action-button">
        <AsyncElement request={deleteCandidateRequest}>
          <Button type="button" color="red" onClick={() => {deleteCandidate(candidate)}}>Submit</Button>
        </AsyncElement>
      </div>
    </span>
  </Modal>;
}

function mapStateToProps(state: State) {
  return {
    deleteCandidateRequest: getDeleteCandidateRequest(state),
    deleteCandidateError: getDeleteCandidateError(state)
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    deleteCandidate: (candidate: Candidate) => {
      dispatch(deleteCandidateRequest(candidate));
    },
    resetDeleteCandidateRequest: () => {
      dispatch(resetDeleteCandidateRequest());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteModalComponent);