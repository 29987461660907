import { connect } from 'react-redux';
import { Dimmer, DimmerDimmable, Loader } from 'semantic-ui-react';
import { PENDING_REQUEST, Request } from '../../../redux/state/requestsState';

interface AsyncTableProps {
  children: JSX.Element;
  request: Request;
}

export const AsyncTableComponent = (props: AsyncTableProps): JSX.Element => {
  const children = props.children;
  const request = props.request;

  const isLoading = request === PENDING_REQUEST;

  return <DimmerDimmable>
    <Dimmer active={isLoading} inverted>
      <Loader active={isLoading} />
    </Dimmer>
    {children}
  </DimmerDimmable>;
}

const AsyncTable = connect()(AsyncTableComponent)

export default AsyncTable;