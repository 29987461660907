import { connect } from "react-redux";
import { Icon } from "semantic-ui-react";
import { Candidate } from "../../../redux/state/candidatesState";
import { useState } from "react";
import DeleteModal from "../Modals/DeleteModal";
import "./DeleteAction.scss";

interface DeleteActionProps {
  candidate: Candidate;
}

function DeleteActionComponent(props: DeleteActionProps) {
  const candidate = props.candidate;
  const [isModalOpen, setModalOpen] = useState(false);

  function openModalHandler() {
    setModalOpen(true);
  }

  function closeModalHandler() {
    setModalOpen(false);
  }

  return (
    <span>
      <Icon className="trash large clickable" color="red" onClick={openModalHandler} />

      {isModalOpen && <DeleteModal closeModal={closeModalHandler} candidate={candidate} />}
    </span>
  );
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps() {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteActionComponent);
