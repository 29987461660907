import { TokenService } from "../services/tokenService";

export function removeToken() {
    sessionStorage.removeItem('token');
}
//get token from storage
export function getToken() : string{
    const token = JSON.parse(sessionStorage.getItem('token'))
    if(!token) return ''
    if(isTokenValid()) return token.AuthenticationResult.IdToken
    else return ''
}
export async function refreshToken() {
    const service = new TokenService();
    let token = JSON.parse(sessionStorage.getItem('token'))
    if(!token) return 'No token present'

    token = await service.refreshUserToken(token.AuthenticationResult.RefreshToken)
    token.ExpiryDate = new Date(new Date().getTime() + token.AuthenticationResult?.ExpiresIn * 1000)
    sessionStorage.setItem('token', JSON.stringify(token))
    return token
}

//function to check existing token and if valid return true
export function isTokenValid(){
    const token = JSON.parse(sessionStorage.getItem('token'))
    if(!token) return false

    if(new Date() >= new Date(token.ExpiryDate))
        return false

    return true
}