import { Credentials } from "../state/authenticateState";
import { Action } from "../utils"

export enum AuthenticateActions {
  AUTHENTICATE_REQUEST = "AUTHENTICATE_REQUEST",
  AUTHENTICATE_SUCCESS = "AUTHENTICATE_SUCCESS",
  AUTHENTICATE_ERROR = "AUTHENTICATE_ERROR"
}

export function authenticateRequest(credentials: Credentials): Action<Credentials> {
  return {
    type: AuthenticateActions.AUTHENTICATE_REQUEST,
    payload: credentials
  };
}

export function authenticateSuccess(): Action {
  return {
    type: AuthenticateActions.AUTHENTICATE_SUCCESS
  };
}

export function authenticateError(error: string): Action {
  return {
    type: AuthenticateActions.AUTHENTICATE_ERROR,
    error: error
  };
}