import { removeToken } from "../../utils/authToken";
import history from "../../utils/history";

export default function Logout() {
    removeToken();
    
    history.replace("/")
    
    location.reload()

    return <div>Logged out</div>;
}
  