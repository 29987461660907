import {
  InstanceHealth,
  severityColors, healthCheckResponse, SeverityLevel
} from "../../redux/state/healthCheckState";
import { Icon, SemanticCOLORS, SemanticICONS, Popup } from "semantic-ui-react";
import "./InstanceHealthView.scss";
import { useEffect , useState} from "react";
import axios from 'axios'

export interface InstanceHealthViewProps {
  instanceHealth: InstanceHealth;
  onUpdate?(value: string): void; 
}
/**
 * This is the InstanceHealthView Functional Component.
 * @param props - The Properties for InstanceHealthView.
 *                @property {instanceHealth} - The Instance Health Data, ie. Severity Level and Unique Id.
 *                @property {onUpdate} - Allows parent to hook into changes in state
 * @returns - The InstanceHealthView JSX
 */
function InstanceHealthView(props: InstanceHealthViewProps): JSX.Element {
  const { instanceHealth } = props;
  const [severityLevel, setSeverityLevel] = useState(instanceHealth.severityLevel)

  const suffix = process.env.REACT_APP_URL_SUFFIX
  const url = `https://${instanceHealth.uuid}.${suffix}/healthcheck`
  const timeout = Number(process.env.REACT_APP_HEALTH_TIMEOUT)

  function onChangeHandler(value: string){
    props.onUpdate(value)
  }
  const getHealth = async () => {
    try{
    const { data } = await axios.get<healthCheckResponse>(url, {
      headers: {
        'Content-Type': 'application/json'
      },
      timeout: 10000
    })
    const newState = data.state
    setSeverityLevel(newState as SeverityLevel)
    onChangeHandler(newState)
    }
    catch(err){
      setSeverityLevel('UNRESPONSIVE')
      onChangeHandler('UNRESPONSIVE')
    }  
  }
  // On mount - will use uuid to request the health of the instance.
  // Eg: https://<uuid>.oneclick-development-main.sandboxalpha.co.uk/healthcheck
  useEffect(() => {
    const interval = setInterval(() => {
      if(new Date() >= new Date(props.instanceHealth.startDate)){
      setSeverityLevel('REFRESHING')
      getHealth()
      } else {
        setSeverityLevel('NA')
        onChangeHandler('NA')
      }
    }, timeout);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="status-icon-container">
      <Popup
        id="status-tooltip"
        flowing
        hoverable
        disabled={severityLevel === "OK" || severityLevel === "NA"}
        content={
          <div className="instance-health-details">
            {severityLevel !== "OK" ? (
              <a href="#" className="info-icon">
                {"Details "}
                <Icon name="info circle" size="large" />
              </a>
            ) : null}
          </div>
        }
        position="right center"
        trigger={
          <div>
            <Icon
              id="instance-health-icon"
              name={
                (severityColors[severityLevel]
                  .icon as SemanticICONS) || "question circle"
              }
              size="large"
              color={
                (severityColors[severityLevel]
                  .color as SemanticCOLORS) || "grey"
              }
            />
            <span className="health-text">
              {severityLevel
                ? severityColors[severityLevel].label
                : "Unknown"}
            </span>
          </div>
        }
      />
    </div>
  );
}

export default InstanceHealthView;
