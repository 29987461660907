import React from "react";
import { Router, Switch, Route } from "react-router-dom";
import Login from "./components/Login/Login";
import history from "./utils/history";
import Layout from "./components/Generic/Layout/Layout";
import Dashboard from "./components/Candidate/Dashboard";
import { isTokenValid } from "./utils/authToken";
import Logout from "./components/Login/Logout";

const App: React.FC = () => {
  const token = isTokenValid();

  if (!token) {
    return (
      <Layout>
        <Login />
      </Layout>
    );
  }

  return (
    <Router history={history}>
      <Layout>
        <Switch>
          <Route path="/" exact>
            <Dashboard />
          </Route>
          <Route path="/logout">
            <Logout />
          </Route>
        </Switch>
      </Layout>
    </Router>
  );
};

export default App;
