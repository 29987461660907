import { Candidate, NewCandidate } from "../redux/state/candidatesState";
import { del, get, post, health } from "./utils";
import { healthCheckResponse } from "../redux/state/healthCheckState";

export class OneClickAPIService {
  public async getCandidates(): Promise<Candidate[]> {
    return await get<Candidate[]>("/candidates");
  }

  public async createCandidate(candidate: NewCandidate): Promise<void> {
    return await post<void>("/candidates", candidate);
  }

  public async deleteCandidate(candidate: Candidate): Promise<void> {
    return await del<void>("/candidates/" + candidate.candidateId);
  }
  public async healthCheck(path: string): Promise<healthCheckResponse>{
    return await health<healthCheckResponse>(path);
  }
}
