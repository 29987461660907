export enum HealthCheckState {
  OK = 0,
  WARNING = 1,
  FAILURE = 2,
  UNRESPONSIVE = 3,
  REFRESHING = 4,
  NA = 5
}
export interface healthCheckResponse {
  state: string
}
export interface InstanceHealth {
  uuid: string;
  severityLevel: SeverityLevel;
  startDate: string
}

export type SeverityLevel = "OK" | "WARNING" | "FAILURE" | "UNRESPONSIVE" | "REFRESHING" | "NA";

export const severityColors: SeverityColor = {
  OK: { icon: "check circle", color: "green", label: "Ok" },
  WARNING: { icon: "warning sign", color: "yellow", label: "Warning" },
  FAILURE: { icon: "warning circle", color: "red", label: "Failure" },
  UNRESPONSIVE: { icon: "warning circle", color: "grey", label: "Unresponsive" },
  REFRESHING: { icon: "refresh", color: "grey", label: "Refreshing"},
  NA: { icon: "clock outline", color: "grey", label: "..." }
};

export type SeverityDetails = {
  [iconName: string]: string;
};

export type SeverityColor = {
  [key in SeverityLevel]: SeverityDetails;
};
