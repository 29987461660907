import { all, AllEffect, ForkEffect } from 'redux-saga/effects';
import { authenticateWatcher } from './authenticateWatcher';
import { candidatesWatcher } from './candidatesWatcher';

export default function* rootWatcher(): Generator<
  AllEffect<Generator<AllEffect<ForkEffect<never>>, void, unknown>>,
  void,
  unknown
> {
  yield all([
    authenticateWatcher(),
    candidatesWatcher()
  ]);
}