import { createSelector } from "reselect";
import { Requests, RequestsState } from "../state/requestsState";
import { State } from "../state/rootState";

function getRequestsState(state: State): RequestsState {
  return state.requests;
}

const getRequestStatuses = createSelector(
  [getRequestsState],
  (state) => state.statuses
);

export const getAuthenticateUserRequest = createSelector(
  [getRequestStatuses],
  (state) => state[Requests.authenticateUser]
);

export const getFetchCandidatesRequest = createSelector(
  [getRequestStatuses],
  (state) => state[Requests.fetchCandidates]
);

export const getCreateCandidateRequest = createSelector(
  [getRequestStatuses],
  (state) => state[Requests.createCandidate]
);

export const getDeleteCandidateRequest = createSelector(
  [getRequestStatuses],
  (state) => state[Requests.deleteCandidate]
);

const getRequestErrors = createSelector(
  [getRequestsState],
  (state) => state.errors
);

export const getAuthenticateUserError = createSelector(
  [getRequestErrors],
  (state) => state[Requests.authenticateUser]
);

export const getFetchCandidatesError = createSelector(
  [getRequestErrors],
  (state) => state[Requests.fetchCandidates]
);

export const getCreateCandidateError = createSelector(
  [getRequestErrors],
  (state) => state[Requests.createCandidate]
);

export const getDeleteCandidateError = createSelector(
  [getRequestErrors],
  (state) => state[Requests.deleteCandidate]
);