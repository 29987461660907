import { Reducer } from "react";
import { Candidate } from "../../redux/state/candidatesState";
import { CandidatesActions } from "../actions/candidates";
import {
  CandidatesState,
  DEFAULT_CANDIDATES_STATE,
} from "../state/candidatesState";
import { Action } from "../utils";

const reducers: Record<string, Reducer<CandidatesState, Action<any>>> = {
  [CandidatesActions.FETCH_CANDIDATES_SUCCESS]: fetchCandidatesSuccessReducer
};

export function candidateReducer(
  state: CandidatesState = DEFAULT_CANDIDATES_STATE,
  action: Action<any>
): CandidatesState {
  const reducer = reducers[action.type];
  return reducer ? reducer(state, action) : state;
}

function fetchCandidatesSuccessReducer(
  state: CandidatesState = DEFAULT_CANDIDATES_STATE,
  action: Action<Candidate[]>
): CandidatesState {
  if (typeof action.payload === "undefined") {
    return state;
  }

  return action.payload.reduce(
    (newState, candidate) => {
      newState[candidate.candidateId] = candidate;
      return newState;
    },
    { ...DEFAULT_CANDIDATES_STATE }
  );
}

