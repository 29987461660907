import { connect } from 'react-redux';
import { Segment } from 'semantic-ui-react';

interface ErrorCardProps {
  children: JSX.Element
}

export const ErrorCardComponent = (props: ErrorCardProps): JSX.Element => {
  return <Segment inverted color="red">
    {props.children}
  </Segment>;
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorCardComponent);