import { useState } from "react";
import { connect } from "react-redux";
import { Button } from "semantic-ui-react";
import CreateModal from "../Modals/CreateModal";

function CreateActionComponent() {
  const [isModalOpen, setModalOpen] = useState(false);

  function openModalHandler() {
    setModalOpen(true);
  }

  function closeModalHandler() {
    setModalOpen(false);
  }

  return (
    <div>
      <Button color="teal" size="massive" onClick={openModalHandler}>
        Register New Candidate Workspace
      </Button>

      {isModalOpen && <CreateModal closeModal={closeModalHandler} />}
    </div>
  );
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps() {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateActionComponent);
