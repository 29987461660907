import { connect } from "react-redux";
import DeleteAction from "../Actions/DeleteAction";
import { Table, Icon } from "semantic-ui-react";
import { Candidate } from "../../../redux/state/candidatesState";
//import TaskActionsPopup from "../../Task/Popup/ActionsPopup";
import InstanceHealthView from "../../HealthCheck/InstanceHealthView";
import {
  InstanceHealth,
  SeverityLevel,
  severityColors,
} from "../../../redux/state/healthCheckState";
import { useState } from "react";

interface CandidateRowProps {
  candidate: Candidate;
  instanceHealth?: InstanceHealth;
}

export const CandidateRowComponent = (
  props: CandidateRowProps
): JSX.Element => {
  const candidate = props.candidate;
  const suffix = process.env.REACT_APP_URL_SUFFIX
  const url = `https://${candidate.candidateId}.${suffix}/`
  candidate.workspaceUrl = url

  const [showLaunch, setShowLaunch] = useState(false)
  function handleStatusChange(event: string){
    setShowLaunch(event !== 'UNRESPONSIVE' && event !== 'NA')
  }

  return (
    <Table.Row
      key={candidate.candidateId}
      className="table-row table-underline"
    >
      <Table.Cell className="actions">
        <DeleteAction candidate={candidate} />
        { showLaunch && <a href={candidate.workspaceUrl} target="_blank" rel="noreferrer"><Icon className={"computer large clickable "} /></a>}
      </Table.Cell>
      <Table.Cell>
        <InstanceHealthView
          instanceHealth={{
            uuid: candidate.candidateId,
            severityLevel: Object.keys(severityColors)[4] as SeverityLevel,
            startDate: candidate.launch
          }} onUpdate={ handleStatusChange } 
        />
      </Table.Cell>
      <Table.Cell>{candidate.candidateName}</Table.Cell>
      <Table.Cell>{candidate.candidate}</Table.Cell>
      <Table.Cell>{candidate.supervisor}</Table.Cell>
      <Table.Cell>{new Date(candidate.launch).toLocaleString()}</Table.Cell>
      <Table.Cell>{new Date(candidate.expiry).toLocaleString()}</Table.Cell>
      <Table.Cell collapsing />
    </Table.Row>
  );
};

export default connect()(CandidateRowComponent);
