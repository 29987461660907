// TODO - Print a table based of GeTable.Cellata module
import { useEffect } from "react";
import "./CandidateTable.scss";
import { connect } from "react-redux";
import { State } from "../../../redux/state/rootState";
import {
  Candidate,
  //MOCKED_CANDIDATES,
} from "../../../redux/state/candidatesState";
import { fetchCandidatesRequest } from "../../../redux/actions/candidates";
import { getCandidates } from "../../../redux/selectors/candidatesSelector";
import { Dispatch } from "../../../redux/store";
import { Button, Table } from "semantic-ui-react";
import CandidateRow from "./CandidateRow";
import { FULFILLED_REQUEST, Request } from "../../../redux/state/requestsState";
import { getFetchCandidatesRequest } from "../../../redux/selectors/requestsSelector";
import { resetFetchCandidatesRequest } from "../../../redux/actions/requests";
import AsyncTable from "../../Generic/Async/AsyncTable";

export interface CandidateTableProps {
  candidates: Candidate[];
  fetchCandidatesRequest: Request;
  fetchCandidates: () => void;
  resetFetchCandidatesRequest: () => void;
}

//const mockedCandidates: Candidate[] = MOCKED_CANDIDATES;

const CandidateTableComponent = (props: CandidateTableProps) => {
  const candidates = props.candidates; //mockedCandidates;
  const fetchCandidatesRequest = props.fetchCandidatesRequest;
  const fetchCandidates = props.fetchCandidates;
  const resetFetchCandidatesRequest = props.resetFetchCandidatesRequest;

  useEffect(() => {
    fetchCandidates();
  }, [fetchCandidates]);

  useEffect(() => {
    if (fetchCandidatesRequest === FULFILLED_REQUEST) {
      resetFetchCandidatesRequest();
    }
  }, [fetchCandidatesRequest]);

  return (
    <div className="candidate-table-container">
      <AsyncTable request={fetchCandidatesRequest}>
        <Table stackable striped basic="very" className="candidate-table">
          <Table.Header>
            <Table.Row className="table-heading">
              <Table.HeaderCell>Actions</Table.HeaderCell>
              <Table.HeaderCell width={2}>Health</Table.HeaderCell>
              <Table.HeaderCell>Candidate Name</Table.HeaderCell>
              <Table.HeaderCell>Candidate</Table.HeaderCell>
              <Table.HeaderCell>Supervisor</Table.HeaderCell>
              <Table.HeaderCell>Launch</Table.HeaderCell>
              <Table.HeaderCell>Expiry</Table.HeaderCell>
              <Table.HeaderCell>
                <Button
                  icon="refresh"
                  circular
                  size="large"
                  color="teal"
                  onClick={fetchCandidates}
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {candidates.map((candidate) => {
              return (
                <CandidateRow
                  key={candidate.candidateId}
                  candidate={candidate}
                />
              );
            })}

            {candidates.length === 0 && (
              <Table.Row textAlign="center">
                <Table.Cell colSpan="8">No Candidates Found</Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </AsyncTable>
    </div>
  );
};

function mapStateToProps(state: State) {
  return {
    candidates: getCandidates(state),
    fetchCandidatesRequest: getFetchCandidatesRequest(state),
  };
}

function mapDipatchToProps(dispatch: Dispatch) {
  return {
    fetchCandidates: () => {
      dispatch(fetchCandidatesRequest());
    },
    resetFetchCandidatesRequest: () => {
      dispatch(resetFetchCandidatesRequest());
    },
  };
}

const CandidateTable = connect(
  mapStateToProps,
  mapDipatchToProps
)(CandidateTableComponent);

export default CandidateTable;
