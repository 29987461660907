import { Action } from "../utils"

export enum RequestActions {
  RESET_AUTHENTICATE_USER_REQUEST = "RESET_AUTHENTICATE_USER_REQUEST",
  RESET_FETCH_CANDIDATES_REQUEST = "RESET_FETCH_CANDIDATES_REQUEST",
  RESET_CREATE_CANDIDATE_REQUEST = "RESET_CREATE_CANDIDATE_REQUEST",
  RESET_DELETE_CANDIDATE_REQUEST = "RESET_DELETE_CANDIDATE_REQUEST"
}

export function resetAuthenticateUserRequest(): Action {
  return {
    type: RequestActions.RESET_AUTHENTICATE_USER_REQUEST
  }
}

export function resetFetchCandidatesRequest(): Action {
  return {
    type: RequestActions.RESET_FETCH_CANDIDATES_REQUEST
  }
}

export function resetCreateCandidateRequest(): Action {
  return {
    type: RequestActions.RESET_CREATE_CANDIDATE_REQUEST
  }
}

export function resetDeleteCandidateRequest(): Action {
  return {
    type: RequestActions.RESET_DELETE_CANDIDATE_REQUEST
  }
}