import { useState } from "react";
import { Credentials } from "../../redux/state/authenticateState";
import { Button, Form, Segment } from "semantic-ui-react";
import AsyncElement from "../Generic/Async/AsyncElement";
import { Request } from "../../redux/state/requestsState";
import { connect } from "react-redux";

export interface LoginFormProps {
  request: Request;
  onSubmit: (credentials: Credentials) => void;
}

function LoginFormComponent(props: LoginFormProps) {
  const request = props.request;
  const onSubmit = props.onSubmit;

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  function submitHandler(e: React.SyntheticEvent) {
    e.preventDefault();

    const credentials: Credentials = {
      email: username,
      password: password,
    };

    onSubmit(credentials);
  }

  return (
    <Form onSubmit={submitHandler} size="large">
      <Segment stacked>
        <Form.Input
          fluid
          icon="user"
          iconPosition="left"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />

        <Form.Input
          fluid
          icon="lock"
          iconPosition="left"
          placeholder="Password"
          value={password}
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          required
        />

        <AsyncElement request={request}>
          <Button type="submit" color="red" fluid size="large">
            Login
          </Button>
        </AsyncElement>
      </Segment>
    </Form>
  );
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps() {
  return {};
}

const LoginForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginFormComponent);

export default LoginForm;
