import {
  all,
  AllEffect,
  call,
  CallEffect,
  ForkEffect,
  put,
  PutEffect,
  takeLatest,
} from "redux-saga/effects";
import { OneClickAPIService } from "../../services/oneClickApiService";
import { Candidate, NewCandidate } from "../../redux/state/candidatesState";
import {
  CandidatesActions,
  fetchCandidatesRequest,
  fetchCandidatesSuccess,
  fetchCandidatesError,
  createCandidateSuccess,
  createCandidateError,
  deleteCandidateSuccess,
  deleteCandidateError,
} from "../actions/candidates";
import { Action } from "../utils";

const service = new OneClickAPIService();

export function* candidatesWatcher(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    takeLatest(
      CandidatesActions.FETCH_CANDIDATES_REQUEST,
      fetchCandidatesRequestWatcher
    ),
    takeLatest(
      CandidatesActions.CREATE_CANDIDATE_REQUEST,
      createCandidateRequestWatcher
    ),
    takeLatest(
      CandidatesActions.DELETE_CANDIDATE_REQUEST,
      deleteCandidateRequestWatcher
    ),
  ]);
}

function* fetchCandidatesRequestWatcher(): Generator<
  CallEffect | PutEffect<Action<Candidate[] | void>>,
  void,
  Candidate[]
> {
  try {
    const candidates = yield call(service.getCandidates);
    yield put(fetchCandidatesSuccess(candidates));
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetchCandidatesError(error.message));
    } else if (typeof error === "string") {
      yield put(fetchCandidatesError(error));
    } else {
      yield put(fetchCandidatesError("Unknown error."));
    }
  }
}

function* createCandidateRequestWatcher(
  action: Action<NewCandidate>
): Generator<CallEffect | PutEffect<Action<void>>, void, Candidate[]> {
  try {
    if (typeof action.payload == "undefined") {
      throw new Error("No candidate provided for creation");
    }

    yield call(service.createCandidate, action.payload);
    yield put(createCandidateSuccess());

    // Once we successfully create the candidate we'll refetch the list
    yield put(fetchCandidatesRequest());
  } catch (error) {
    if (error instanceof Error) {
      yield put(createCandidateError(error.message));
    } else if (typeof error === "string") {
      yield put(createCandidateError(error));
    } else {
      yield put(createCandidateError("Unknown error."));
    }
  }
}

function* deleteCandidateRequestWatcher(
  action: Action<Candidate>
): Generator<CallEffect | PutEffect<Action<void>>, void, Candidate[]> {
  try {
    if (typeof action.payload == "undefined") {
      throw new Error("No candidate provided for deletion");
    }

    yield call(service.deleteCandidate, action.payload);
    yield put(deleteCandidateSuccess());

    // Once we successfully delete the candidate we'll refetch the list
    yield put(fetchCandidatesRequest());
  } catch (error) {
    if (error instanceof Error) {
      yield put(deleteCandidateError(error.message));
    } else if (typeof error === "string") {
      yield put(deleteCandidateError(error));
    } else {
      yield put(deleteCandidateError("Unknown error."));
    }
  }
}
