import { connect } from 'react-redux';
import CandidateTable from "./Table/CandidateTable";
import CreateCandidateAction from "./Actions/CreateAction";
import "./Dashboard.scss";

function DashboardComponent() {
  return (
    <div className='dashboard-container'>
      <div>
        <CreateCandidateAction />
      </div>
      <div className="divider"></div>
      <div>
        <CandidateTable />
      </div>
    </div>
  );
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardComponent);