import { useEffect } from "react";
import { connect } from "react-redux";
import { Dispatch } from "../../../redux/store";
import DetailsForm from "../Forms/DetailsForm";
import { NewCandidate } from "../../../redux/state/candidatesState";
import { createCandidateRequest } from "../../../redux/actions/candidates";
import "reactjs-popup/dist/index.css";
import Modal from "../../Generic/Modal/Modal";
import { FAILED_REQUEST, FULFILLED_REQUEST, Request } from "../../../redux/state/requestsState";
import { State } from "../../../redux/state/rootState";
import { getCreateCandidateError, getCreateCandidateRequest } from "../../../redux/selectors/requestsSelector";
import { resetCreateCandidateRequest } from "../../../redux/actions/requests";
import ErrorCard from "../../Generic/Card/ErrorCard";

interface CreateModalProps {
  closeModal: () => void;
  createCandidateRequest: Request;
  createCandidateError: string | null;
  createCandidate: (candidate: NewCandidate) => void;
  resetCreateCandidateRequest: () => void;
}

export const CreateModalComponent = (props: CreateModalProps): JSX.Element => {
  const closeModal = props.closeModal;
  const createCandidateRequest = props.createCandidateRequest;
  const createCandidateError = props.createCandidateError;
  const createCandidate = props.createCandidate;
  const resetCreateCandidateRequest = props.resetCreateCandidateRequest;

  useEffect(() => {
    if (createCandidateRequest === FULFILLED_REQUEST) {
      resetCreateCandidateRequest();
      closeModal();
    }
  }, [createCandidateRequest]);

  return <Modal title="Register Candidate" closeModal={closeModal}>
    <span>
      {
        createCandidateRequest === FAILED_REQUEST &&
        <ErrorCard>
          <span>{createCandidateError || "An unknown error occured"}</span>
        </ErrorCard>
      }

      <DetailsForm onSubmit={createCandidate} request={createCandidateRequest} />
    </span>
  </Modal>;
}

function mapStateToProps(state: State) {
  return {
    createCandidateRequest: getCreateCandidateRequest(state),
    createCandidateError: getCreateCandidateError(state)
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    createCandidate: (candidate: NewCandidate) => {
      dispatch(createCandidateRequest(candidate));
    },
    resetCreateCandidateRequest: () => {
      dispatch(resetCreateCandidateRequest());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateModalComponent);