export interface NewCandidate {
  candidate: string;
  supervisor: string;
  launch: string;
  expiry: string;
  candidateName: string;
}

export interface Candidate extends NewCandidate {
  candidateId: string;
  password: string;
  isRunning: boolean;
  workspaceUrl: string;
}

export type CandidatesState = {
  [candidateId: string]: Candidate;
};

export const DEFAULT_CANDIDATES_STATE: CandidatesState = {};

// static data for testing
export const MOCKED_CANDIDATES: Candidate[] = [...Array(10)].map((index) => {
  return {
    candidate: "hello@hello.co.uk",
    supervisor: "supervisor@caci.co.uk",
    launch: new Date().toString(),
    expiry: new Date().toString(),
    candidateId: `candidate_${index}`,
    password: "password",
    isRunning: false,
    workspaceUrl: "",
    candidateName: ""
  };
});
