export interface Request {
  pending: boolean;
  fulfilled: boolean;
  failed: boolean;
}

export const DORMANT_REQUEST: Request = {
  pending: false,
  fulfilled: false,
  failed: false,
};

export const PENDING_REQUEST: Request = {
  pending: true,
  fulfilled: false,
  failed: false,
};

export const FULFILLED_REQUEST: Request = {
  pending: false,
  fulfilled: true,
  failed: false,
};

export const FAILED_REQUEST: Request = {
  pending: false,
  fulfilled: false,
  failed: true,
};

export enum Requests {
  authenticateUser = "authenticateUser",
  fetchCandidates = "fetchCandidates",
  createCandidate = "createCandidate",
  deleteCandidate = "deleteCandidate"
}

export type RequestsState = {
  statuses: Record<string, Request>,
  errors: Record<string, string | null>
};

export const DEFAULT_REQUESTS_STATE: RequestsState = {
  statuses: {
    [Requests.authenticateUser]: DORMANT_REQUEST,
    [Requests.fetchCandidates]: DORMANT_REQUEST,
    [Requests.createCandidate]: DORMANT_REQUEST,
    [Requests.deleteCandidate]: DORMANT_REQUEST
  },
  errors: {
    [Requests.authenticateUser]: null,
    [Requests.fetchCandidates]: null,
    [Requests.createCandidate]: null,
    [Requests.deleteCandidate]: null
  }
};