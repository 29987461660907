import { Candidate, NewCandidate } from "../../redux/state/candidatesState";
import { Action } from "../utils";

export enum CandidatesActions {
  FETCH_CANDIDATES_REQUEST = "FETCH_CANDIDATES_REQUEST",
  FETCH_CANDIDATES_SUCCESS = "FETCH_CANDIDATES_SUCCESS",
  FETCH_CANDIDATES_ERROR = "FETCH_CANDIDATES_ERROR",
  CREATE_CANDIDATE_REQUEST = "CREATE_CANDIDATE_REQUEST",
  CREATE_CANDIDATE_SUCCESS = "CREATE_CANDIDATE_SUCCESS",
  CREATE_CANDIDATE_ERROR = "CREATE_CANDIDATE_ERROR",
  DELETE_CANDIDATE_REQUEST = "DELETE_CANDIDATE_REQUEST",
  DELETE_CANDIDATE_SUCCESS = "DELETE_CANDIDATE_SUCCESS",
  DELETE_CANDIDATE_ERROR = "DELETE_CANDIDATE_ERROR",
}

export function fetchCandidatesRequest(): Action {
  return {
    type: CandidatesActions.FETCH_CANDIDATES_REQUEST,
  };
}

export function fetchCandidatesSuccess(
  candidates: Candidate[]
): Action<Candidate[]> {
  return {
    type: CandidatesActions.FETCH_CANDIDATES_SUCCESS,
    payload: candidates,
  };
}

export function fetchCandidatesError(error: string): Action {
  return {
    type: CandidatesActions.FETCH_CANDIDATES_ERROR,
    error: error,
  };
}

export function createCandidateRequest(
  candidate: NewCandidate
): Action<NewCandidate> {
  return {
    type: CandidatesActions.CREATE_CANDIDATE_REQUEST,
    payload: candidate,
  };
}

export function createCandidateSuccess(): Action {
  return {
    type: CandidatesActions.CREATE_CANDIDATE_SUCCESS,
  };
}

export function createCandidateError(error: string): Action {
  return {
    type: CandidatesActions.CREATE_CANDIDATE_ERROR,
    error: error,
  };
}

export function deleteCandidateRequest(
  candidate: Candidate
): Action<Candidate> {
  return {
    type: CandidatesActions.DELETE_CANDIDATE_REQUEST,
    payload: candidate,
  };
}

export function deleteCandidateSuccess(): Action {
  return {
    type: CandidatesActions.DELETE_CANDIDATE_SUCCESS,
  };
}

export function deleteCandidateError(error: string): Action {
  return {
    type: CandidatesActions.DELETE_CANDIDATE_ERROR,
    error: error,
  };
}
