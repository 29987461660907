import { connect } from "react-redux";
import { useState } from "react";
import { Button, Form } from "semantic-ui-react";
import { NewCandidate } from "../../../redux/state/candidatesState";
import { Request } from "../../../redux/state/requestsState";
import AsyncElement from "../../Generic/Async/AsyncElement";
import DatePicker from "react-datepicker"
import  "react-datepicker/dist/react-datepicker.min.css"

export interface DetailsFormProps {
  request: Request;
  onSubmit: (candidate: NewCandidate) => void;
}

function DetailsFormComponent(props: DetailsFormProps) {
  const request = props.request;
  const onSubmit = props.onSubmit;

  const [candidateEmail, setCandidate] = useState("");
  const [supervisorEmail, setSupervisor] = useState("");
  const [launch, setLaunch] = useState(new Date().toISOString());
  const [expiry, setExpiry] = useState(new Date().toISOString());
  const [candidateName, setCandidateName] = useState("");

  function submitHandler(e: React.SyntheticEvent) {
    e.preventDefault();

    const newCandidate: NewCandidate = {
      candidate: candidateEmail,
      supervisor: supervisorEmail,
      launch: launch,
      expiry: expiry,
      candidateName: candidateName
    };

    onSubmit(newCandidate);
  }

  const handleColor = (time: Date) => {
    return time.getHours() > 12 ? "text-success" : "text-error";
  }; 

  const filterPassedTime = (time: Date) => {
    const currentDate = new Date(launch);
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };  

  return (
    <Form onSubmit={submitHandler}>
      <Form.Input
        label="Candidate Name"
        type="text"
        placeholder="Candidate name"
        value={candidateName}
        onChange={(e) => setCandidateName(e.target.value)}
        required
      />
      <Form.Input
        label="Candidate"
        type="Email"
        placeholder="Candidate email address"
        value={candidateEmail}
        onChange={(e) => setCandidate(e.target.value)}
        required
      />
      <Form.Input
        label="Supervisor"
        type="Email"
        placeholder="Supervisor email address"
        value={supervisorEmail}
        onChange={(e) => setSupervisor(e.target.value)}
        required
      />

      <div className="ui two column centered grid">
        <div className="row">
          <div className="column">
            <label>Launch</label>
            <DatePicker
              showTimeSelect
              popperPlacement="top-end"
              selected={new Date(launch)}
              onChange={(date) => setLaunch(date.toISOString())}
              timeClassName={handleColor}
              dateFormat="MMMM d, yyyy h:mm aa"
            />
          </div>    

          <div className="column">
            <label>Expiry</label>
            <DatePicker
              showTimeSelect
              popperPlacement="top-end"
              selected={new Date(expiry)}
              onChange={(date) => setExpiry(date.toISOString())}
              timeClassName={handleColor}
              minDate={new Date(launch)}
              filterTime={filterPassedTime}
              dateFormat="MMMM d, yyyy h:mm aa"
            />
          </div>
        </div>
      </div>

      <div className="action-button">
        <AsyncElement request={request}>
          <Button type="submit" color="teal">
            Submit
          </Button>
        </AsyncElement>
      </div>
    </Form>
  );
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps() {
  return {};
}

const DetailsForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailsFormComponent);

export default DetailsForm;
