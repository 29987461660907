import { Credentials, CognitoResponse } from "../redux/state/authenticateState";
import { auth, refresh } from "./utils";

export class TokenService {
    public async authenticateUser(credentials: Credentials): Promise<CognitoResponse> {
        return await auth<CognitoResponse>(credentials);
    }
    public async refreshUserToken(token: string) : Promise<CognitoResponse>{
        return await refresh<CognitoResponse>(token);
    }
}