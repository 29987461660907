import { connect } from "react-redux";
import "./Modal.scss";
import { Icon, Modal } from "semantic-ui-react";


interface ModalProps {
  title: string;
  children: JSX.Element;
  closeModal: () => void;
  size?: "small" | "mini" | "tiny" | "large" | "fullscreen";
}

function ModalComponent(props: ModalProps) {
  return (
    <Modal open={true} className="modal" size={props.size || "small"} onClose={props.closeModal}>
      <Modal.Header>
        {props.title}
        <Icon className="close-icon" name="close" onClick={props.closeModal} />
      </Modal.Header>
      <Modal.Content>{props.children}</Modal.Content>
    </Modal>
  );
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalComponent);
