import { createSelector } from "reselect";
import { CandidatesState } from "../state/candidatesState";
import { State } from "../state/rootState";

function getCandidatesState(state: State): CandidatesState {
  return state.candidates;
}

export const getCandidates = createSelector(
  [getCandidatesState],
  (candidates) => Object.values(candidates)
);
