import { combineReducers, Reducer } from "redux";
import { State } from "../state/rootState";
import { Action } from "../utils";
import { candidateReducer } from "./candidatesReducer";
import { requestsReducer } from "./requestsReducer";

const rootReducer: Reducer<State, Action<any>> = combineReducers({
  candidates: candidateReducer,
  requests: requestsReducer
});

export default rootReducer;