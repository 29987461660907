import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducers/rootReducer';
import { applyMiddleware, createStore } from 'redux';
import rootWatcher from './watchers/rootWatcher';

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
    rootReducer,
    applyMiddleware(sagaMiddleware)
);

sagaMiddleware.run(rootWatcher);

export default store;

export type Dispatch = typeof store.dispatch;