import { Loader } from 'semantic-ui-react';
import { PENDING_REQUEST, Request } from '../../../redux/state/requestsState';
import "./AsyncElement.scss";

interface AsyncElementProps {
  request: Request,
  children: JSX.Element,
  size?: "mini" | "tiny" | "small" | "medium" | "large" | "big" | "huge" | "massive"
  inline?: boolean | "centered"
}

export const AsyncElementComponent = (props: AsyncElementProps): JSX.Element => {
  const request = props.request;
  const children = props.children;
  const size = props.size;
  const inline = props.inline;

  const isLoading = request === PENDING_REQUEST;

  if (isLoading) {
    return <Loader active size={size || "medium"} inline={inline || "centered"} />;
  }

  return children;
}

const AsyncElement = AsyncElementComponent;

export default AsyncElement;