import { connect } from "react-redux";
import { resetAuthenticateUserRequest } from "../../redux/actions/requests";
import { Grid, Header, Image, Message } from "semantic-ui-react";
import {
  getAuthenticateUserError,
  getAuthenticateUserRequest,
} from "../../redux/selectors/requestsSelector";
import { Credentials } from "../../redux/state/authenticateState";
import {
  FAILED_REQUEST,
  FULFILLED_REQUEST,
  Request,
} from "../../redux/state/requestsState";
import { State } from "../../redux/state/rootState";
import { Dispatch } from "../../redux/store";
import LoginForm from "./LoginForm";
import { useEffect } from "react";
import { authenticateRequest } from "../../redux/actions/authenticate";

interface LoginProps {
  authenticateUserRequest: Request;
  authenticateUserError: string | null;
  createCredentials: (credentials: Credentials) => void;
  resetAuthenticateUserRequest: () => void;
}

export const LoginComponent = (props: LoginProps): JSX.Element => {
  const authenticateUserRequest = props.authenticateUserRequest;
  const authenticateUserError = props.authenticateUserError;
  const createCredentials = props.createCredentials;
  const resetAuthenticateUserRequest = props.resetAuthenticateUserRequest;

  useEffect(() => {
    if (authenticateUserRequest === FULFILLED_REQUEST) {
      resetAuthenticateUserRequest();
    }
  }, [authenticateUserRequest]);

  return (
    <Grid textAlign="center" style={{ height: "50vh" }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as="h1" color="black" textAlign="center">
          <Image src="/logo.png" /> Log-in to your account
        </Header>

        <LoginForm
          onSubmit={createCredentials}
          request={authenticateUserRequest}
        />
        {authenticateUserRequest === FAILED_REQUEST && (
          <Message color="red">
            <span>{authenticateUserError || "Authentication failed."}</span>
          </Message>
        )}
      </Grid.Column>
    </Grid>
  );
};

function mapStateToProps(state: State) {
  return {
    authenticateUserRequest: getAuthenticateUserRequest(state),
    authenticateUserError: getAuthenticateUserError(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    createCredentials: (credentials: Credentials) => {
      dispatch(authenticateRequest(credentials));
    },
    resetAuthenticateUserRequest: () => {
      dispatch(resetAuthenticateUserRequest());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginComponent);
